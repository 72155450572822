
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "contract details",
  components: {},
  data() {
    return {
      trainer: {},
      api_url: "",
      contractInfo:{},
      load: false,
      id: this.$route.params.id,
      tabIndex: ref(0),
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.load = true;
    await this.getContractDetails();
  },
  methods: {
    async getContractDetails() {
      this.load = true;
      await ApiService.get("entity/contract/show/" + `${this.id}`)
        .then((response) => {
          this.load = false;
          this.contractInfo = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        this.load = false;
        });
    },
  },
});
